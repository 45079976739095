html {
    scroll-behavior: smooth;
}

.plot-sticky-nav {
    width: 100%;
    position: fixed;
    z-index: 2;
    left: 0;
    bottom: 0;
    background-color: #fff;

    &.hide-on-mobile {
        display: none;

        @include breakpoint(large) {
            display: block;
        }
    }

    @include breakpoint(large) {
        position: sticky;
        top: 0;
        bottom: unset;
    }

    &.info-bar-present {
        @include breakpoint(large) {
            top: 50px;
        }
    }

    &::before {
        @include breakpoint(1024px down) {
            content: "";
            width: 100%;
            height: 25px;
            background: linear-gradient(180deg, transparent 0%, rgba(58,61,62,0.09) 100%);
            display: block;
            position: absolute;
            top: -25px;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 20px 18px 15px;

        @include breakpoint(large) {
            gap: 12px;
            padding: 10px 0;
            flex-direction: row;
            min-height: 75px;
        }
    }

    &__left,
    &__right {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__left {
        width: 100%;
        gap: 13px;
        padding-bottom: 2px;
        position: relative;

        @include breakpoint(medium) {
            justify-content: center;
        }

        @include breakpoint(large) {
            justify-content: flex-start;
            width: auto;
            gap: 16px;
        }

        @include breakpoint(xlarge) {
            gap: 30px;
        }

        a {
            font-family: $primary-font;
            color: $color-primary-blue;
            font-size: clamp(14px, 2vw, 1rem);
            font-style: normal;
            font-weight: 400;
            text-decoration: none;
            cursor: pointer;

            @include breakpoint(xlarge) {
                font-size: $text-size-small;
            }

            &:hover,
            &:focus {
                text-decoration: underline;
            }

            span {
                white-space: nowrap;
            }
        }
        .slick-slider {
          overflow: hidden;
          min-height: 28px;
        }
        .slick-list {
          margin: 6px 20px;
        }
        a:not(.slick-slide), a {
          margin-right: 13px;
        }
        .slick-arrow {
          position: absolute;
          top: 50%;
          z-index: 5;
          transform: translateY(-50%);          
          width: 15px;
          height: 18px;
          text-indent: -9999px;
          overflow: hidden;
        }

        .slick-prev {
          display: flex !important;
          border: 0;
          left: 0;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M8 13.1l-8-8 2.1-2.2 5.9 5.9 5.9-5.9 2.1 2.2z'/%3E%3C/svg%3E");
          transform-origin: 17px 9px;
          transform: rotate(90deg);
          background-repeat: no-repeat;
      }

      .slick-next {
          display: flex !important;
          border: 0;
          right: 0;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M8 13.1l-8-8 2.1-2.2 5.9 5.9 5.9-5.9 2.1 2.2z'/%3E%3C/svg%3E");
          transform-origin: 2px 6px;
          transform: rotate(-90deg);
          background-repeat: no-repeat;

      }
  
        .slick-next {
            right: -5px;
        }
  
        .slick-prev {
            left: -10px;
        }
    }

    &__right {
        gap: 16px;
        flex-wrap: wrap;

        @include breakpoint(medium) {
            gap: 12px;
        }

        @include breakpoint(xlarge) {
            gap: 16px;
        }

        >a:first-child {
            color: $color-white;
            background-color: $color-primary-blue;
            border-color: transparent;

            &:hover,
            &:focus {
                color: $color-primary-blue;
                background-color: transparent;
                font-family: $primary-font;
                border-color: $color-primary-blue;
            }
            
            @include breakpoint(medium down) {
                width: 100%;
            }
        }

        a {
            flex-grow: 1;

            &[href*="download"], 
            &.plot-sticky-nav--download {
                display: none;

                @include breakpoint(500px) {
                    display: block;
                }
            }
        }
    }

    .c-button {
        color: $color-primary-blue;
        background-color: transparent;
        font-family: $primary-font;
        font-size: $text-size-x-small;
        font-style: normal;
        text-align: center;
        padding: 12px 20px;
        display: inline-block;
        text-decoration: none;
        border-color: $color-primary-blue;
        border-style: solid;
        border-width: 2px;
        transition: color 0.2s, background-color 0.2s, border-color 0.2s;

        @include breakpoint(large) {
            padding: 12px 25px;
        }

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-primary-blue;
        }

        &.solid {
            color: $color-white;
            background-color: $color-primary-blue;
            border-color: transparent;

            &:hover,
            &:focus {
                color: $color-primary-blue;
                background-color: transparent;
                font-family: $primary-font;
                border-color: $color-primary-blue;
            }
        }
    }
}

.c-sidebar--secondary {
    .c-sidebar--secondary--visible {
        display: none;

        @include breakpoint(large) {
            display: flex;
        }
    }
}
