.o-departments-listing {
    @include clearfix;
    @extend %u-section-separation--border;

    .o-departments-listing__cards {
        position: relative;
        margin-top: 25px;
        padding: 20px 0 0;
    }

    .c-layout__column {
        width: 100%;

        @include breakpoint(large) {
            @include xy-cell(9, $gutter-output: false, $gutter-type: 'margin');
        }
    }

    &__card {
        width: 100%;

        @include breakpoint(large) {
            @include xy-cell(4);
        }
    }

    &--hidden {
        display: none;
    }
}
