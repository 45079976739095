﻿%c-rich-text-block,
.c-rich-text-block {
  font-family: $primary-font;
  font-weight: $text-weight-light;
  color: $color-primary-blue;

  p {
    font-weight: $text-weight-light;
    color: $color-primary-blue;
    @extend %c-bodytext--regular;

    strong {
      font-weight: $text-weight-bold;
    }

    li {
      text-decoration: none;
    }
  }

  li {
    display: list-item;
    margin-left: 20px;
    list-style: disc;
  }

  // Ensure that a orphan strong element is styled in same way as a p > strong element.
  strong {
    font-weight: $text-weight-light;
    color: $color-primary-blue;
    @extend %c-bodytext--regular;
    font-weight: $text-weight-bold;
  }

  .c-button {
    @include breakpoint(768px) {
      &--mobile {
        display: none;
      }
    }

    span {
      display: inline-block;
      height: auto;
      line-height: 45px;
      color: $color-primary-blue;
      text-align: center;
      border: 2px solid $color-primary-blue;
      padding: 0 20px;
      min-width: 200px;
      transition: background-color 0.2s ease;
      text-decoration: none;
      font-weight: $text-weight-regular;

      @include breakpoint(medium only) {
        padding: 0 10px;
      }
    }

    &:hover span {
      color: white;
      background-color: $color-primary-blue;
      cursor: pointer;
    }
  }

  .c-button--default {
    display: inline-block;
    height: auto;
    line-height: 45px;
    color: $color-primary-blue;
    text-align: center;
    border: 2px solid $color-primary-blue;
    padding: 0 20px;
    width: 200px;
    transition: background-color 0.2s ease;
    text-decoration: none;
    font-weight: $text-weight-regular;
    margin: 0 auto;
    text-decoration: none;

    &:hover {
      color: white;
      background-color: $color-primary-blue;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .c-button--purple {
    @extend %c-button--purple;

    &:hover {
      color: $color-white;
      text-decoration: none;
    }
  }

  .c-button--blue {
    @extend %c-button--blue;

    &:hover {
      color: $color-white;
      text-decoration: none;
    }
  }

  .c-link {
    span {
      display: inline-block;
      height: auto;
      line-height: 45px;
      color: $color-purple;
      padding: 0 $sizing-regular;
      min-width: 200px;
      text-decoration: none;

      &:after {
        transform: rotate(7deg);
      }

      &:hover {
        &:after {
          top: -20%;
        }
      }
    }

    &:hover span {
      cursor: pointer;
    }
  }

  a {
    color: $color-purple;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      color: $color-primary-blue;
      text-decoration: underline;
    }
  }
}

%c-button {
  @include breakpoint(768px) {
    &--mobile {
      display: none;
    }
  }

  span {
    display: inline-block;
    height: auto;
    line-height: 45px;
    color: $color-primary-blue;
    text-align: center;
    border: 2px solid $color-primary-blue;
    padding: 0 20px;
    min-width: 200px;
    transition: background-color 0.2s ease;
    text-decoration: none;
    font-weight: $text-weight-regular;
    @include breakpoint(medium only) {
      padding: 0 10px;
    }
  }
  &:hover span {
    color: white;
    background-color: $color-primary-blue;
    cursor: pointer;
  }
}

.c-link-arrow {
  font-family: $primary-font;
  color: $color-purple;
  font-weight: $text-weight-regular;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    background-image: $svg-chevron-up;
    width: 12px;
    height: 6px;
    margin: 3px 0 0 5px;
    background-repeat: no-repeat;
    transition: background-image 0.3s;
  }

  &.is-toggled .c-link-arrow__icon {
    background-image: $svg-chevron-down;
  }
}

%c-heading {
  font-weight: $text-weight-regular;

  @include breakpoint(medium) {
    font-weight: $text-weight-regular;
  }

  color: $color-primary-blue;
}

%c-heading--large,
.c-heading--large {
  @extend %c-heading;
  text-transform: uppercase;
  line-height: $text-line-height-large;
  font-size: $text-size-x-large;
  margin: 0 0 $sizing-xx-small 0;

  @include breakpoint(medium) {
    margin: 0 0 20px 0;
    line-height: $text-line-height-xxxx-large;
    font-size: $text-size-xxxx-large;
  }

  &-light {
    font-weight: $text-weight-light;
  }
}
%c-heading--big,
.c-heading--big {
  @extend %c-heading;
  font-size: $text-size-x-small;
  font-weight: $text-weight-light;

  @include breakpoint(medium) {
    font-size: $text-size-large;
    line-height: normal;
  }
}

%c-heading--medium,
.c-heading--medium {
  @extend %c-heading;
  line-height: $text-size-large;
  margin: 0 0 $sizing-x-small 0;
  font-size: $text-size-small;
  font-weight: $text-weight-light;
  @include breakpoint(medium) {
    margin: 0 0 20px 0;
    line-height: $text-size-regular;
    margin: 0 0 20px 0;
    font-size: $text-size-regular;
  }
}

%c-heading--regular,
.c-heading--regular {
  @extend %c-heading;
  text-transform: uppercase;
  line-height: $text-size-small;
  font-size: $text-size-small;
  margin: 0 0 20px 0;
  @include breakpoint(medium) {
    margin: 0 0 20px 0;
    font-size: $text-size-regular;
  }
}

%c-heading--small,
.c-heading--small {
  @extend %c-heading;
  font-weight: $text-weight-light;
  font-size: $text-size-xxx-small;
  margin: 0 0 8px 0;
  @include breakpoint(medium) {
    margin: 0 0 8px 0;
    font-size: $text-size-xx-small;
  }
}

%c-bodytext--regular,
.c-bodytext--regular {
  margin: 0 0 $sizing-x-small 0;
  font-size: $text-size-xx-small;
  line-height: $text-line-height-xx-small;
  @include breakpoint(medium) {
    margin: 20px 0;
    font-size: $text-size-x-small;
    line-height: $text-line-height-xx-small;
  }
}

%u-section-separation {
  margin-bottom: 0;
  @include breakpoint(medium) {
    &::before {
      content: "";
      margin-bottom: 50px;
      display: table;
    }
    &::after {
      content: "";
      margin-bottom: 50px;
      display: table;
    }
    &.thin{
      &::before{
        margin-bottom: 0;
      }
    }
  }
}

%u-section-separation--double {
  @include breakpoint(medium) {
    &::before {
      margin-bottom: 80px;
    }
    &::after {
      margin-bottom: 80px;
    }
  }
}

%u-section-border {
  border-top: solid 1px #ced5df;
}

%u-section-separation--border {
  @extend %u-section-separation;

  &::before {
    @extend %u-section-border;
    content: "";
    margin-bottom: 50px;
    width: 100%;
    display: table;
  }
  &::after {
    content: "";
    width: 100%;
    margin-bottom: 50px;
    display: table;
  }
}

// Ensure the margins are removed from the first component on the page.
main section:first-child::before {
  content: none;
  margin: 0;
}

main section:first-child {
  div.c-hero-overlay.thin {
    @include breakpoint(medium) {
      margin: auto !important;
      top: 35% !important;
    }
  }
}

%c-promotion-block__content,
.c-promotion-block__content {
  text-align: center;
  overflow: hidden;
  position: relative;
  text-align: center;
}

%c-promotion-block__content--copy,
.c-promotion-block__content--copy {
  padding: 20px 20px 0 20px;
  @include breakpoint(medium) {
    padding: 20px;
  }
  @extend %c-rich-text-block;
  h1 {
    @extend %c-heading--large;
  }
  h2 {
    @extend %c-heading--medium;
  }
  h3 {
    @extend %c-heading--regular;
  }
  .c-heading--light {
    font-weight: $text-weight-light;
  }

  .date--spaced {
    display: inline-block;
    padding-bottom: 30px;
  }

  a * {
    color: $color-purple;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      color: $color-primary-blue;
      text-decoration: underline;
    }
  }
}

%c-promotion-block__content--image,
.c-promotion-block__content--image {
  // JS solution negates need for object-fit here, but including in-case we feature detect later for fallback.
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    display: inline-block;
    vertical-align: bottom;
  } // Using a JS snippet to provide images embedded into slide as a background image to deal with lack of imagefit support for IE.
  &.u-compat-object-fit {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    img,
    picture {
      // hide image if object fit is not supported - opacity to 0 for the link area
      opacity: 0;
    }
  }
}

%c-promotion-block__content--video,
.c-promotion-block__content--video {
  // Extending promotion block image class, as an image is used as a placeholder for video content.
  @extend %c-promotion-block__content--image;
  .u-overlay__trigger {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    &::before {
      position: absolute;
      z-index: 1;
      display: block;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
      /*adjust brightness here */
      background-image: $svg-play-icon;
      background-repeat: no-repeat;
      background-position: center center;
      transition: opacity 0.75s ease;
      opacity: 0;
    }
    &:hover::before {
      opacity: 1;
      transition: opacity 1s ease;
      cursor: pointer;
    }
  }
  .u-overlay {
    position: absolute;
    top: 0;
    opacity: 0;
    z-index: -1;
    .u-overlay__close {
      position: absolute;
      top: -$sizing-regular;
      right: -18px;
      background-image: $svg-close-icon;
      background-repeat: no-repeat;
      background-position: center center;
      height: $sizing-regular;
      width: $sizing-regular;
      @include squish-text();
    }
    &--active {
      position: fixed;
      opacity: 1;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    background: rgba(0, 0, 0, 0.9);
    /*adjust brightness here */
    transition: opacity 0.75s ease;
  }
  .c-video {
    max-width: 900px;
    width: 100%;
    .c-video__inner {
      position: relative;
      padding-bottom: 56.25%;
      /* 16:9 */
      padding-top: 25px;
      height: 0;
    }
    .c-video__inner iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

%c-promotion-block__content--overlay,
.c-promotion-block__content--overlay {
  // Extending promotion block image class, as an image is used as a background for this block type.
  @extend %c-promotion-block__content--image;
  img {
    position: absolute;
  }
  .c-promotion-block__content--copy {
    position: relative;
    @include breakpoint(medium) {
      padding: $sizing-regular;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  margin-bottom: 40px;
  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

%c-article-block__content,
.c-article-block__content {
  text-align: left;
  overflow: hidden;
  position: relative;

  ul {
    list-style: initial;
    padding-left: 20px;
  }

  ol {
    list-style: decimal;
    padding-left: 20px;
  }

  h1 {
    font-size: $text-size-xxxx-large;
  }

  h2 {
    font-size: $text-size-xxx-large;
  }

  h3 {
    font-size: $text-size-x-large;
  }

  h4 {
    font-size: $text-size-large;
  }
}

%u-hyperlink,
.u-hyperlink {
  color: $color-primary-blue;

  &:hover {
    color: $color-purple;
    text-decoration: underline;
  }
}

%cta-sheen {
  overflow: hidden;
  position: relative;

  &:after {
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0.08) 77%,
      rgba(255, 255, 255, 0.3) 92%,
      rgba(255, 255, 255, 0) 100%
    );
    content: "";
    height: 200%;
    left: -210%;
    opacity: 0;
    position: absolute;
    top: -110%;
    transform: rotate(30deg);
    width: 200%;
  }

  &:not(&--disabled):hover:after,
  &:not(.disabled):hover:after,
  &:not([disabled]):hover:after {
    left: -30%;
    opacity: 1;
    top: -30%;
    transition: {
      duration: 0.7s, 0.7s, 0.15s;
      property: left, top, opacity;
      timing-function: ease;
    }
  }
}

%primary-button,
.primary-button {
  @extend %cta-sheen;
  background-color: $color-purple;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-size: $text-size-x-small;
  font-weight: $text-weight-regular;

  &[disabled] {
    opacity: 0.95;
    background-color: desaturate(lighten($color-purple, 20%), 25%);
  }
}

%secondary-button,
.secondary-button {
  @extend %c-bodytext--regular;
  font-family: $primary-font;
  height: auto;
  line-height: 45px;
  color: $color-primary-blue;
  text-align: center;
  border: 2px solid $color-primary-blue;
  padding: 0 20px;
  min-width: 200px;
  transition: background-color 0.2s ease;
  text-decoration: none;

  @include breakpoint(medium only) {
    padding: 0 10px;
  }

  @include breakpoint(medium) {
    &:hover {
      color: white;
      background-color: $color-primary-blue;
      cursor: pointer;
    }
  }
}

%c-button--purple,
.c-button--purple {
  @extend %primary-button;
  display: inline-block;
  height: auto;
  line-height: 45px;
  text-align: center;
  padding: 0 20px;
  width: 200px;
  text-decoration: none;
  font-weight: $text-weight-regular;
  margin: 0 auto;
  color: $color-white;
  &:after {
    transform: rotate(7deg);
  }
}
%c-button--blue,
.c-button--blue {
  @extend %c-button--purple;
  background-color: $color-primary-blue;
}

%carousel-button {
  background-color: $color-primary-blue;
  height: $sizing-regular;
  width: $sizing-regular;
  @include squish-text;
  background-repeat: no-repeat;
  background-position: center center;
}

%carousel-button--prev {
  @extend %carousel-button;
  background-image: $svg-chevon-left;
}

%carousel-button--next {
  @extend %carousel-button;
  background-image: $svg-chevon-right;
}

%search-result {
  display: block;
  background: #ffffff;
  border-bottom: $border-light;
  background-repeat: no-repeat;
  min-height: $sizing-regular;
  line-height: 38px;
  padding-left: $sizing-regular;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: $text-size-xx-small;
  font-weight: $text-weight-light;
  &:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
  }
  &.u-hover {
    a {
      color: $color-purple;
      text-decoration: underline;
    }
  }
  a {
    @extend %u-hyperlink;
    text-decoration: none;
    display: table-cell;
    vertical-align: middle;
    &:focus {
      outline: 0;
    }
  }
  @include breakpoint(msmall) {
    font-size: $text-size-x-small;
  }
  @include breakpoint(lsmall) {
    font-size: $text-size-small;
  }
  @include breakpoint(medium) {
    font-size: rem(17px) !important;
  }
  @include breakpoint(xlarge) {
    font-size: $text-size-small !important;
  }
}

%hero-search {
  .c-hero-overlay {
    position: relative;
    z-index: 9;
    padding-bottom: 10px;

    &.thin{
      position: absolute;
      width: 100%;
      top: 60%;
    }

    @include breakpoint(medium) {
      position: absolute; // z-index: 0;
      width: calc(50% - 1.25rem);
      left: calc(25% + 0.3125rem);
      top: 50%;
      margin-top: -30px;
      &.thin{
        // margin: 0px 0px auto auto;
        left: calc(50% - 296px);
        width: 592px;
        top: 50%;
      }
    }

    .c-search__form {
      position: relative;
      transform: translate3d(0, 0, 0);
      margin: $sizing-x-small 3% 0;

      @include breakpoint(msmall) {
        margin: $sizing-x-small 5%;
        margin-bottom: 0px;
      }

      @include breakpoint(medium) {
        margin: 0;
        @include xy-cell();
        width: 100% !important;
        margin-left: 0px !important;
        height: 100%;
        @include xy-cell(6);
        @include xy-cell-offset(3);
        &.thin{
          margin: auto !important;
          width: 592px !important;
        }
      }
      // Search input bar styling.

      &--static-banner {
        margin-top: -50px;
        @include breakpoint(medium) {
          @include xy-cell(7);
          @include xy-cell-offset(1);
        }
      }

      .c-search__title {
        font-size: 1.75rem;
        font-weight: 400;
        text-transform: uppercase;
        color: $color-primary-blue;
        background: rgb(230, 230, 230);
        text-align: center;
        padding: 16px 50px;

        @include breakpoint(medium) {
          font-size: 3rem;
          font-weight: 300;
          color: #fff;
          background: transparent;
          text-align: left;
          padding: 25px 0;
        }
      }

      .c-search__input {
        position: relative;
        display: block; // Calculate width to make room for search button (which is 60px).
        height: $sizing-regular;
        width: calc(100% - #{$sizing-regular});
        padding: 0px $sizing-x-small;
        @include placeholder {
          color: rgba($black, 0.5);
        }
        color: $black;
        background-color: rgba(255, 255, 255, 0.8);
        caret-color: $color-purple; // May want to rationalise text styling / use a mixin?
        font-family: $primary-font;
        font-weight: $text-weight-regular;
        font-size: $text-size-x-small;
        @include breakpoint(medium) {
          font-size: $text-size-small;
        } // Border required on mobile / hide for desktop.@
        border: solid 1px rgba($color-primary-blue, 0.3);
        border-width: 1px 0 1px 1px;
        outline: none;
        @include breakpoint(medium) {
          border: none;
        }
      } // Search button positioned next to text input.
      .c-search__button {
        @extend %primary-button;
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        background-color: $color-purple;
        width: $sizing-regular;
        height: $sizing-regular;
        @include search-icon;
        background-position: center center;

        &[disabled] {
          opacity: 1;
          background-color: $color-purple;
        }
      }
    }
    .c-search__autocomplete {
      border: $border-light;
      border-top: none;
      display: none;
      @include breakpoint(small) {
        position: absolute;
        left: 3%;
        right: 3%;
      }
      @include breakpoint(msmall) {
        left: 5%;
        right: 5%;
      }
      @include breakpoint(medium) {
        position: static;
        @include xy-cell(6);
        @include xy-cell-offset(3);
        z-index: 10;
        box-shadow: rgba(9, 9, 9, 0.5) 0px 0px 1px;

        &--static-banner {
          @include xy-cell(7);
          @include xy-cell-offset(1);
        }

        width: 100% !important;
        margin-left: 0px !important;
      }

      &.u-visible {
        display: block;
        overflow-y: scroll;
        max-height: 600px;
        @include breakpoint(small)
        {
          max-height: 400px;
        }
      }

      .c-search__places {
        li {
          @extend %search-result;
          background-image: $svg-icon-location;
          background-position: 24px center;
          font-size: $text-size-x-small;
        }
      }

      .c-search__developments {
        li {
          @extend %search-result;
          background-image: $svg-house-red;
          background-position: 20px center;
          background-size: 24px;
          font-size: $text-size-x-small;
        }
      }
    }

    .o-search__link-container {
      display: none;
      border: $border-light;
      border-top: 0;
      font-size: $text-size-x-small;
      position: absolute;
      left: 3%;
      right: 3%;
      top: 60px;

      @include breakpoint(msmall) {
        left: 5%;
        right: 5%;
      }

      @include breakpoint(medium) {
        @include xy-cell(6);
        @include xy-cell-offset(3);
        left: 0;
        right: 0;
        width: 100% !important;
        margin-left: 0px !important;
      }

      &.u-visible {
        display: block;
      }

      &--static-banner {
        top: unset;

        @include breakpoint(medium) {
          @include xy-cell(7);
          @include xy-cell-offset(1);
        }
      }

      .c-search__list-all {
        @extend %search-result;
        @extend %u-hyperlink;
        text-decoration: none;
        background-image: $svg-house-red;
        background-position: 18px center;
        background-size: 24px;
        font-size: $text-size-x-small;

        span {
          display: table-cell;
          vertical-align: middle;
        }
      }

      .c-search__by-location {
        @extend %search-result;
        @extend %u-hyperlink;
        font-size: $text-size-x-small;
        text-decoration: none;
        background-image: $svg-icon-location;
        background-position: 20px center;

        span {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
  }
}

%hero-carousel {
  // Image styling
  .c-carousel-slides__slide {
    // Black semi-transparent overlay

    &:not(:first-child) {
      display: none;
    }
    img {
      max-width: 100%;
      width: 100%;
      vertical-align: bottom;
      display: inline-block;
    }
  }

  // Styling for pager
  ul.c-carousel-pager {
    position: absolute;
    bottom: 8px;
    display: block !important;
    width: 100%;
    text-align: center;
    padding: 0 3%;
    z-index: 2;

    &.u-hover {
      opacity: 1;
      transition: none;
    }

    li {
      width: 30px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.5);
      margin: 0 $sizing-xxx-small;
      display: inline-block;

      button {
        height: 10px;
      }

      &.slick-active {
        border-color: $white;
      }
    }

    button {
      @include squish-text;
      height: 30px;
      width: 30px;
    }

    @include breakpoint(msmall) {
      padding: 0 5%;
      bottom: 15px;
    }

    @include breakpoint(lsmall) {
      li {
        //     margin: auto;

        button {
          height: 30px;
        }
      }
    }

    @include breakpoint(medium) {
      bottom: 25px;
      width: calc(50% - 1.25rem);
      left: calc(25% + 0.3125rem);
    }
  }
  @include breakpoint(small) {
    ul.c-carousel-pager {
      transition: none;
    }
  }

  // Styling for pager transition
  @include breakpoint(medium) {
    ul.c-carousel-pager {
      opacity: 0;
      transition: all 0.75s ease;
    }
    &:hover ul.c-carousel-pager {
      opacity: 1;
      transition: all 0.75s ease;
    }
  }

  // Styling for previous and next buttons
  .c-carousel-slides {
    overflow: hidden;

    button.slick-arrow {
      display: none;
      opacity: 0;
      background-color: $color-primary-blue;
      position: absolute;
      z-index: 2;
      top: 50%;
      height: $sizing-regular;
      width: $sizing-regular;
      margin-top: -30px;
      @include squish-text;
      background-repeat: no-repeat;
      background-position: center center;
    }
    button.slick-prev {
      left: 0;
      background-image: $svg-chevon-left;
    }
    button.slick-next {
      right: 0;
      background-image: $svg-chevon-right;
    }

    // Styling for previous and next button transitions
    @include breakpoint(medium) {
      button.slick-arrow {
        display: block;
        opacity: 0;
        transition: all 0.75s ease;
      }
      &.u-hover button.slick-arrow {
        opacity: 1;
        transition: all 1.75s ease;
      }
    }
  }
}

%three-quarter-content {
  @include xy-cell(12, $gutter-output: false, $gutter-type: "padding");

  @include breakpoint(large) {
    @include xy-cell(9, $gutter-output: false, $gutter-type: "padding");
  }
}

%ten-column-carousel {
  @include xy-grid;
  @extend %u-section-separation--border;
  @extend %hero-carousel;
  padding-left: 3%;
  padding-right: 3%;
  position: relative;

  @include breakpoint(msmall) {
    padding-left: 5%;
    padding-right: 5%;
  }

  .c-carousel-slides {
    @include xy-cell(12, $gutter-output: false, $gutter-type: "padding");
    overflow: visible;

    @include breakpoint(large) {
      @include xy-cell(10);
      @include xy-cell-offset(1);
    }

    .c-carousel-pager {
      bottom: -30px;

      li {
        border-bottom: 2px solid rgba($color-primary-blue, 0.3);

        &.slick-active {
          border-bottom: 2px solid rgba($color-primary-blue, 1);
        }
      }
    }
  }

  .c-carousel-slides__slide {
    @extend %c-rich-text-block;
    @extend %c-promotion-block__content--copy;
    padding: 0;
    text-align: center;

    h1 {
      font-weight: 300;
    }

    p {
      margin-bottom: 30px;

      @include breakpoint(large) {
        margin-bottom: 50px;
      }
    }

    &::before {
      content: none;
    }
  }

  .c-carousel-slides__copy {
    display: inline-block;
  }

  .c-carousel-slides__copy h1.c-carousel-slides__header,
  .c-carousel-slides__copy h2.c-carousel-slides__header {
    @extend %c-heading--large;
  }

  .c-carousel__back-button {
    @extend %carousel-button--prev;
    position: absolute;
    left: 0;
    top: 55%;
    opacity: 0;
    transition: opacity 0.75s ease;
    display: none !important;

    @include breakpoint(large) {
      display: block !important;
    }
  }

  .c-carousel__next-button {
    @extend %carousel-button--next;
    position: absolute;
    right: 0;
    top: 55%;
    opacity: 0;
    transition: opacity 0.75s ease;
    display: none !important;

    @include breakpoint(medium) {
      display: block !important;
    }
  }

  .c-carousel-pager {
    position: static !important;
  }

  &:hover {
    .c-carousel__back-button,
    .c-carousel__next-button {
      opacity: 1;
    }
  }
}

%carousel-iframe {
  .c-carousel-slides__iframe-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
  }

  .c-carousel-slides__iframe-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1 !important;

    iframe {
      @extend .c-carousel-slides__iframe-content;
    }
  }

  .c-carousel-slides__iframe-thumbnail {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    * {
      min-height: 100%;
      min-width: 100%;
    }
  }

  .c-carousel-slides__iframe-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: #000000;
    opacity: 0.5;
  }

  .c-carousel-slides__iframe-button {
    position: absolute;
    height: 84px;
    width: 84px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: $svg-play-icon;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 4;
    font-family: $primary-font;
  }
}

%video-iframe {
  .c-video__iframe-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
  }

  .c-video__iframe-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    iframe {
      @extend .c-video__iframe-content;
    }
  }

  .c-video__iframe-thumbnail {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    * {
      min-height: 100%;
      min-width: 100%;
    }
  }

  .c-video__iframe-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #000000;
    opacity: 0.5;
  }

  .c-video__iframe-button {
    position: absolute;
    height: 84px;
    width: 84px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: $svg-play-icon;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 3;
    font-family: $primary-font;
  }
}

%sidebar-item {
  @include breakpoint(large) {
    width: 100%;
    flex-shrink: 0;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    padding: 10px 14px;
    order: 1;
  }

  @include breakpoint(xlarge) {
    padding: 10px 20px;
  }

  @include breakpoint(xxlarge) {
    padding: 10px 30px;
  }
}

%image-label,
.image-label {
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 35px;
    right: -95px;
    width: 300px;
    padding: 15px 50px;
    transform-origin: center;
    transform: rotate(45deg);
    color: white;
    font-size: $text-size-xxx-small;
    font-weight: $text-weight-regular;
  }
}

%image-label--featured-home,
.image-label--featured-home {
  &::after {
    content: "Home of the week";
    background-color: $color-purple;
  }
}

%image-label--coming-soon,
.image-label--coming-soon {
  &::after {
    content: "Coming Soon";
    background-color: $color-warm-yellow;
    color: $color-primary-blue;
  }
}

%image-label--special-offer,
.image-label--special-offer {
  &::after {
    content: attr(data-offer-text);
    background-color: $color-primary-blue;
  }
}
%tabs {
  .tabs-container {
    @include tabs-container;
  }

  .tabs-title {
    @include tabs-title;
  }

  .tabs-content {
    @include tabs-content;
  }

  .tabs-panel {
    @include tabs-panel;
  }
}

%center,
.center {
  text-align: center;
}
%left,
.left {
  text-align: left;
}
%right,
.right {
  text-align: right;
}
.file-icon,
%file-icon {
  background-image: $svg-file;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: inline-block;
}
