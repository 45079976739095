﻿.o-promotion-block {
    // Using a JS snippet to provide images embedded into slide as a background image to deal with lack of imagefit support for IE.
    .c-promotion-block {
      &__content {
        text-align: center;
        overflow: hidden;
        position: relative;
  
        img {
          width: 100%;
          height: 100%;
          max-width: 100%;
          object-fit: contain;
        }
  
        &--copy {
          padding: $sizing-xx-small $sizing-x-small;
  
          @include breakpoint(medium) {
            padding: $sizing-small $sizing-xx-small 0;
          }
  
          @include breakpoint(large) {
            padding: $sizing-small 0 0 0;
          }
  
          @extend %c-rich-text-block;
  
          h3 {
            @extend %c-heading--regular;
          }
  
          h2 {
            @extend %c-heading--large;

            &:not(.c-promotion-block__content--copy-heading) {
              @extend %c-heading--medium;
            }
          }
  
          margin-top: $sizing-x-small;
          flex-grow: 1;
          padding-bottom: 40px;
          text-align: center;
  
          @include breakpoint(medium) {
            padding-bottom: 40px;
            margin-top: 0;
  
            a {
              bottom: 0;
              position: absolute;
              left: 0;
              width: 100%;
            }
          }
        }
  
        &--image {
          aspect-ratio: 4 / 3;
  
          &.u-compat-object-fit {
            background-size: contain;
            background-position: center center;
  
            img, picture {
              opacity: 0;

              &.img-in-rich-text {
                opacity: 1;
                position: static;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  
    .o-promo-item {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
}