﻿.o-footer {
    position: relative;
    margin-top: 60px;
    // Main footer menu positioning
    .c-footer__main,
    .c-footer__upper,
    .c-footer__lower {
        @include grid-container-custom;
        padding-left: 0;
        padding-right: 0;

        @include breakpoint(small) {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }

    .c-footer__upper {
        padding: $sizing-xxx-small;

        @include breakpoint(medium) {
            padding: 0;
        }

        .o-footer__upper-container {
            @include xy-grid-collapse();
            display: flex;

            @include breakpoint(medium) {
                @include xy-grid;
                @include xy-gutters($grid-margin-gutters, margin, right left, true);
            }
        }
    }


    .c-footer__main {
        overflow-x: hidden;

        > .grid-x.grid-margin-x {

            @include breakpoint(lsmall down) {
                margin-left: 0;
                margin-right: 0;
            }

            @include breakpoint(medium) {
                @include xy-gutters($grid-margin-gutters, margin, right left, true);
            }
        }

        @include breakpoint(medium) {
            padding-top: 15px;
            padding-bottom: 15px;
            overflow-x: visible;
        }

        .o-footer-menu-main {
            @include xy-grid-collapse();
            width: 100%;

            @include breakpoint(medium) {
                @include xy-cell(9);
            }

            @include breakpoint(large) {
                @include xy-cell(10);
            }

            @include breakpoint(xxlarge) {
                @include xy-cell(11);
            }

            .c-footer-menu {
                width: 100%;

                .c-footer-menu__item {

                    > ol {
                        height: 55px;
                        overflow-y: hidden;

                        li:first-child {
                            a {
                                background-image: $svg-arrow-down;
                                background-position: right 15px center;
                                background-repeat: no-repeat;
                            }
                        }
                    }

                    @include breakpoint(medium) {

                        @include xy-cell-reset();
                        width: calc(20% - 20px);
                        margin: 0 10px;
                        float: left;

                        &:first-child {
                            margin-left: 0;
                        }

                        > ol {
                            height: auto;
                            overflow-y: auto;

                            li:first-child {
                                a {
                                    background-image: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .c-footer-logo-links {
            @include xy-grid;
            padding-top: 10px;
            padding-bottom: 10px;

            ol {
                @include xy-cell(5, false);
                max-width: 130px;
                width: 100%;
            }

            @include breakpoint(medium) {
                float: right;
                @include xy-cell(3);
                justify-content: end;
                position: relative;
                padding-top: 0;
                padding-bottom: 0;

                .flex-center {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                ol {
                    @include xy-cell-reset();
                    right: 0;
                    bottom: 15px;

                    li {
                        max-height: 100px;
                        margin: 0;


                        &:first-child {
                            margin-bottom: 20px;
                            max-height: none;
                        }

                        img {
                            max-height: 65px;
                            width: auto;
                        }

                        #firstLogo {
                            img {
                                max-height: none;
                                height: 35mm;
                                width: 30mm;
                            }
                        }
                    }
                }
            }

            @include breakpoint(large) {
                @include xy-cell(2);
            }

            @include breakpoint(xxlarge) {
                @include xy-cell(1);
            }
        }
    }
    // Lower footer menu positioning.
    .c-footer__lower {
        overflow: hidden;

        .c-copyright {
            margin-top: $sizing-x-small;
            margin-bottom: $sizing-x-small;
            margin-left: 3% !important;
            margin-right: 3% !important;
            padding: 0;
            display: block;

            @include breakpoint(msmall) {
                margin-left: 5% !important;
                margin-right: 5% !important;
            }

            @include breakpoint(medium) {
                width: 15%;
                margin-left: 0 !important;
                margin-right: 0 !important;
                display: inline-block;

                p {
                    margin-bottom: 0;
                }
            }
        }

        .o-footer-sub-menu {
            margin-left: 3% !important;
            margin-right: 3% !important;
            margin-top: $sizing-x-small;
            margin-bottom: $sizing-x-small;
            padding: 0;

            @include breakpoint(msmall) {
                margin-left: 5% !important;
                margin-right: 5% !important;
            }

            @include breakpoint(medium) {
                width: 85%;
                margin-left: 0 !important;
                margin-right: 0 !important;
                float: right;
                text-align: right;
            }

            li {
                display: block;

                @include breakpoint(medium) {
                    display: inline-block;
                }
            }
        }
    }
}
