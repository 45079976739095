﻿.o-layout-4col {
    position: relative;
    overflow: hidden;

    &:not(&--nested) {
        @extend %u-section-separation;
    }

    &--bordered {
        border-top: solid 1px #ced5df;
    }

    &--nested {
        .c-layout__column {
            display: flex;
            position: relative;
            margin: 20px 0;
            @include xy-cell(12);

            @include breakpoint(large) {
                margin: 0;
                @include xy-cell(3);
            }
        }
        
    }

    .c-layout__column {
        display: flex;
        position: relative;
        margin: 10px 0;
        @include xy-cell(12, false);
        width: 100%;

        @include breakpoint(medium) {
            margin: 0;

            @include xy-cell(3);
            float: left;

            &--3quarters {
                @include xy-cell(9);
            }

            &--half {
                @include xy-cell(6);
            }
        }
    }

    &--center {
        .c-layout__column {
            @include xy-cell(12, $gutter-type: 'padding');
            padding-left: 0;
            padding-right: 0;
    
            @include breakpoint(large) {
                margin: 0;
    
                @include xy-cell(4, $gutter-type: 'padding');
                @include xy-cell-offset(4);            
            }
        }
    }

}

