﻿.o-layout-3col {
    position: relative;
    overflow: hidden;
    @extend %u-section-separation;

    .c-layout__column {
        display: flex;
        position: relative;
        margin: {
            top: 20px;
            bottom:20px;
        };
        @include xy-cell(12, false);
        width: 100%;

        @include breakpoint(medium) {
            margin: 0;

            @include xy-cell(4);
            float: left;
        } 

        @include target-ie {
            display: block;
        }
    }

    &--break-large {
        .c-layout__column {
            @include breakpoint(medium) {
                @include xy-cell(12);
                margin: {
                    top: 20px;
                    bottom:20px;
                };
            } 

            @include breakpoint(large) {
                @include xy-cell(4);
            }
        }
    }

}
