.o-layout-1col {
    position: relative;
    overflow: hidden;
    @extend %u-section-separation;

    &.o-full-width-content {
        margin: 0 0 $sizing-small 0 !important;
        width: 100vw;
        left: calc(-50vw + 50%);
        position: relative;

        &::before {
          content: '';
          margin-bottom: 50px;
        }
    }

    .c-column-layout__inner {
        display: flex;
        flex-direction: column;

        @include breakpoint(lsmall) {
            flex-direction: row;

            ul.c-column-layout__column {
                padding: 20px;
                flex: 1;
            }

            ul.c-column-layout__column:first-child {
                padding-left: 0;
            }

            ul.c-column-layout__column:last-child {
                padding-right: 0;
            }
        }

        @include breakpoint(medium) {
            ul.c-column-layout__column {
                flex: 0 0 40vw;
            }
        }

        @include breakpoint(large) {
            ul.c-column-layout__column {
                flex: 0 0 25vw;
            }
        }

        @include breakpoint(xlarge) {
            ul.c-column-layout__column {
                flex: 0 0 20vw;
            }
        }
    }


    .c-layout__column {
        position: relative;
    }
}
